import createPlugin from '@@/create-plugin';
import { history, request, useActions, useBook, useEventTarget, useFile, useInPreview } from 'topwrite';
import { useEffect, useMemo } from 'react';
import { createSubBook, useSubBook } from '@/lib/sub-book';
import { getPluginConfig } from '@@/use-config';

export default createPlugin({
    components: {
        'editor:catalog:menu:item': () => import('./components/editor/menu-item'),
        'editor:catalog:items': () => import('./components/editor/items'),
        'editor:catalog:part:title': () => import('./components/editor/part-title'),
        'editor:catalog:part': () => import('./components/editor/part'),
        'page:asset': () => import('./components/reader/asset'),
        'page:menu:item': () => import('./components/reader/menu')
    },
    async register(context, book) {

        const config = getPluginConfig(book.config);

        const subBook = createSubBook(config.getValue('host', location.origin));

        if (context === 'reader') {
            await Promise.all(book.summary.parts.map(async (part) => {
                const id = part.getMetadata('reference');
                if (id) {
                    const articles = await subBook.getArticles(id);
                    if (articles) {
                        part.articles = articles;
                    }
                }
            }));

            book.summary.indexLevels();
        }
    },
    activate(context) {
        const et = useEventTarget();

        if (context === 'editor') {
            useEffect(() => {

                const listener: TypeEventListener<'beforeCreatePart' | 'beforeUpdatePart'> = ({ detail }) => {
                    detail.schema.properties = {
                        ...detail.schema.properties,
                        reference: {
                            title: '关联文档',
                            type: 'string',
                        }
                    };

                    detail.uiSchema = {
                        ...detail.uiSchema,
                        reference: {
                            'ui:help': '关联文档后该分组下的章节替换为被关联文档的所有章节，只能关联已发布的公开文档',
                            'ui:options': {
                                widget: 'typeahead',
                                placeholder: '输入文档名称搜索',
                                async onSearch(params: { query?: string, value?: string }) {
                                    const subBook = useSubBook();
                                    if (params.query) {
                                        const books = await subBook.getBooks(params.query);

                                        return books.map((book) => {
                                            return {
                                                value: book.hash_id,
                                                label: book.name
                                            };
                                        });
                                    }
                                    if (params.value) {
                                        const book = await subBook.getBook(params.value);
                                        if (book) {
                                            return [
                                                {
                                                    value: book.hash_id,
                                                    label: book.name
                                                }
                                            ];
                                        }
                                    }
                                }
                            }
                        }
                    };
                };

                et.addEventListener('beforeCreatePart', listener);
                et.addEventListener('beforeUpdatePart', listener);
            }, []);
        } else {
            const { intercept, fetch } = useActions('page');
            const { setHandler } = useActions('search');
            const { summary } = useBook();
            const preview = useInPreview();
            const subBook = useSubBook();
            const file = useFile();

            useMemo(() => {
                intercept(({ request }) => {
                    request.use(subBook.interceptRequest());
                });
            }, []);

            useEffect(() => {
                if (!preview && file) {
                    //TODO 检查服务端是否支持全文搜索
                    setHandler(async (keyword) => {
                        try {
                            const { data } = await request.post<any[]>(file.relative('search'), {
                                keyword,
                                ids: subBook.ids
                            });

                            return data.map((item) => {
                                if (subBook.ids.includes(item.book)) {
                                    item.path = subBook.resolvePath(item.book, item.path);
                                }

                                return {
                                    title: item.title,
                                    path: item.path
                                };
                            });
                        } catch {
                            return [];
                        }
                    });
                }
            }, [file]);

            const articleMatch = useMemo(() => {
                const article = summary.getArticle(article => article.getPath() === file.path);

                return article || file.path === 'index.html';
            }, [file]);

            useEffect(() => {
                if (articleMatch) {
                    if (file.content === undefined) {
                        //重新加载
                        fetch(history.location.pathname);
                    }
                } else {
                    const id = subBook.resolveBook(file.path);
                    if (id) {
                        const article = summary.getArticle(() => {
                            return true;
                        }, (part) => part.getMetadata('reference') === id);

                        if (article) {
                            history.replace(file.relative(article.path));
                            return;
                        }
                    }

                    //跳转首页
                    history.replace(file.relative('/'));
                }
            }, []);

            if (!articleMatch) {
                return;
            }

        }
    }
});
